import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { raffleService } from '../../services/api';

// Thunks
export const fetchAwards = createAsyncThunk(
  'awards/fetchAwards',
  async (eventId) => await raffleService.getAwards(eventId)
);

export const fetchCurrentAward = createAsyncThunk(
  'awards/fetchCurrentAward',
  async (awardId) => await raffleService.getAward(awardId)
);

const initialState = {
  items: [],
  currentAward: null,
  status: 'idle',
  error: null
};

export const awardsSlice = createSlice({
  name: 'awards',
  initialState,
  reducers: {
    updateAwardQuantity: (state, action) => {
      const { awardId, remainingQuantity } = action.payload;
      const award = state.items.find(item => item.awardId === awardId);
      if (award) {
        award.remainingQuantity = remainingQuantity;
      }
    },
    resetAwardQuantity: (state, action) => {
      const award = state.items.find(a => a.awardId === action.payload);
      if (award) {
        award.remainingQuantity = award.totalQuantity;
      }
    },
    setCurrentAward: (state, action) => {
      state.currentAward = action.payload;
    },
    reduceRemainingQuantity: (state, action) => {
        state.items = state.items.map(item => item.awardId === action.payload ? { ...item, remainingQuantity: item.remainingQuantity - 1 } : item);
        state.currentAward = state.items.find(item => item.awardId === action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAwards.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAwards.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
        state.currentAward = action.payload.find(award => award.awardId === state.currentAward?.awardId);
      })
      .addCase(fetchAwards.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchCurrentAward.fulfilled, (state, action) => {
        state.currentAward = action.payload;
      });
  }
});

export const { updateAwardQuantity, resetAwardQuantity, setCurrentAward, reduceRemainingQuantity } = awardsSlice.actions;
export default awardsSlice.reducer; 