import { useEffect, useState } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { useDispatch } from 'react-redux';
import { setConnected, setDisconnected } from '../store/slices/connectionSlice';
const BASE_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL;

export const useSignalR = () => {
    const [connection, setConnection] = useState(null);
    const dispatch = useDispatch();
    
    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(`${BASE_URL}/hubs/raffle`)
            .withAutomaticReconnect()
            .build();

        newConnection.start()
            .then(() => {
                console.log('Connected to SignalR');
                dispatch(setConnected());
            })
            .catch(err => {
                console.error('Error connecting to SignalR:', err);
                dispatch(setDisconnected());
            });

        newConnection.onclose(() => {
            console.log('Disconnected from SignalR');
            dispatch(setDisconnected());
        });

        newConnection.onreconnected(() => {
            console.log('Reconnected to SignalR');
            dispatch(setConnected());
        });

        setConnection(newConnection);

        return () => {
            if (newConnection) {
                newConnection.stop();
            }
        };
    }, [dispatch]);

    return connection;
};
