import React from 'react';
import { useSelector } from 'react-redux';
import '../css/App.css';
import Marquee from "react-fast-marquee";


const AwardsFeed = () => {
    const { items: awards } = useSelector(state => state.awards);

    return (
        <Marquee gradientColor={[255, 255, 255]} gradient={true} speed={80} autoFill={true}>
            {awards.map((award, index) => (
                <div key={award.awardId} className='itemAward'>
                    <h2>{award.name}</h2>
                    <p><span className='myBadget'>Quedan: {award.remainingQuantity}/{award.totalQuantity}</span></p>
                </div>
            ))}
        </Marquee>
    );
};

export default AwardsFeed;