import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Label, Input } from 'reactstrap';
import { fetchAwards, setCurrentAward } from '../../store/slices/awardsSlice';

const AwardSelector = ({disabled, handleChange}) => {
    const dispatch = useDispatch();
    const { items: awards, currentAward } = useSelector(state => state.awards);
    const { currentEvent } = useSelector(state => state.events);

    useEffect(() => {
        if (currentEvent) {
            dispatch(fetchAwards(currentEvent.eventId));
        }
    }, [currentEvent, dispatch]);

    const handleAwardChange = async (awardId) => {
        const selectedAward = awards.find(a => a.awardId === parseInt(awardId));
        if (selectedAward) {
            await handleChange(awardId);
            dispatch(setCurrentAward(selectedAward));
        } else {
            dispatch(setCurrentAward(null));
        }
    };

    if (!currentEvent) {
        return <div>Seleccione un evento primero</div>;
    }

    return (
        <div className="config-section">
            <FormGroup>
                <Label for="awardSelect">Premio Actual</Label>
                <Input
                    type="select"
                    name="awardSelect"
                    id="awardSelect"
                    value={currentAward?.awardId || ''}
                    onChange={(e) => handleAwardChange(e.target.value)}
                    disabled={disabled}
                >
                    <option value="">Seleccione un premio</option>
                    {awards.map(award => (
                        <option key={award.awardId} value={award.awardId}>
                            {award.name} (Quedan: {award.remainingQuantity}/{award.totalQuantity})
                        </option>
                    ))}
                </Input>
            </FormGroup>
        </div>
    );
};

export default AwardSelector; 