const BASE_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL;
const COMPANY_ID = 1;

export const raffleService = {
  getAttendants: async (eventId) => {
    
    try {
      const response = await fetch(`${BASE_URL}/api/raffle/attendants/${eventId}`);
      return await response.json();
    } catch (error) {
      console.error('Error fetching attendants:', error);
      return [];
    }
  },

  getAwards: async (eventId) => {
    
    try {
      const response = await fetch(`${BASE_URL}/api/raffle/awards/${eventId}`);
      return await response.json();
    } catch (error) {
      console.error('Error fetching awards:', error);
      return [];
    }
  },

  getAward: async (awardId) => {
    try {
      const response = await fetch(`${BASE_URL}/api/raffle/awards/current/${awardId}`);
      return await response.json();
    } catch (error) {
      console.error('Error fetching award:', error);
      return null;
    }
  },

  updateAward: async (awardId, data) => {

    try {
      const response = await fetch(`${BASE_URL}/api/raffle/awards/${awardId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });
      return await response.json();
    } catch (error) {
      console.error('Error updating award:', error);
      return false;
    }
  },
  getLastWinners: async (eventId) => {
    
    try {
      const response = await fetch(`${BASE_URL}/api/raffle/winners/${eventId}`);
      return await response.json();
    } catch (error) {
      console.error('Error fetching last winners:', error);
      return [];
    }
  },

  async acceptWinner(winner) {
    const response = await fetch(`${BASE_URL}/api/raffle/winners/accept`, {
      method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(winner)
      });

      if (!response.ok) throw new Error('Error accepting winner');
      return await response.json();
    },
    async deliverWinnerAward(winnerId) {
      const response = await fetch(`${BASE_URL}/api/raffle/winners/deliver/${winnerId}`, {
        method: 'POST'
        });
  
        if (!response.ok) throw new Error('Error delivering winner award');
        return await response.json();
      },
      async deleteWinner(winnerId) {
        const response = await fetch(`${BASE_URL}/api/raffle/winners/delete/${winnerId}`, {
          method: 'DELETE'
        });
  
        if (!response.ok) throw new Error('Error deleting winner');
        return await response.json();
      },
      async resetWinners(eventId) {
        const response = await fetch(`${BASE_URL}/api/raffle/winners/reset/${eventId}`, {
          method: 'POST'
        });
        return await response.json();
      },
      async toggleAttendantAssistance(employeeId, isAssisted, eventId) {
        const response = await fetch(`${BASE_URL}/api/raffle/attendants/toggle/${employeeId}/${isAssisted}/${eventId}`, {
          method: 'POST'
        });
        return await response.json();
      }
  };
  



export const eventService = {
  getEvents: async () => {

    try {
      const response = await fetch(`${BASE_URL}/api/raffle/events/${COMPANY_ID}`);
      return await response.json();
    } catch (error) {
      console.error('Error fetching events:', error);
      return [];
    }
  },

  getCurrentEvent: async () => {
    
    try {
      const response = await fetch(`${BASE_URL}/api/raffle/events/current/${COMPANY_ID}`);
      return await response.json();
    } catch (error) {
      console.error('Error fetching current event:', error);
      return null;
    }
  }
};

export const configService = {
  getConfiguration: async () => {
    const response = await fetch(`${BASE_URL}/api/raffle/config/${COMPANY_ID}`);
    if (!response.ok) throw new Error('Error fetching configuration');
    return await response.json();
  },

  updateConfiguration: async (eventId, awardId) => {
    const response = await fetch(`${BASE_URL}/api/raffle/config/${COMPANY_ID}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ eventId, awardId }),
    });
    if (!response.ok) throw new Error('Error updating configuration');
    return await response.json();
  }
}; 