import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicket, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { 
    handleWinnerAcceptance, 
    setPendingWinner, 
    setExpanded, 
    fetchLastWinners,
    setAutoSpinEnabled,
    setShowWinnersPopup,
    setShowWinnersType
} from '../../store/slices/raffleSlice';
import { useSignalR } from '../../hooks/useSignalR';
import AlertMessage from '../common/AlertMessage';
import { fetchAwards } from '../../store/slices/awardsSlice';

const PendingWinner = ({connection, spin, config}) => {
    const dispatch = useDispatch();
    const { pendingWinner, autoSpinEnabled, showWinnersType } = useSelector(state => state.raffle);
    const { currentAward } = useSelector(state => state.awards);
    const { currentEvent } = useSelector(state => state.events);

    useEffect(() => {
        if (connection) {
            connection.on('PendingWinnerSelected', winner => {
                dispatch(setPendingWinner(winner));
            });

            connection.on('ShowWinners', () => {
                dispatch(setShowWinnersPopup({show: true, type: showWinnersType}));
            });
        }
    }, [connection, showWinnersType]);

    if (!pendingWinner) {
        return null;
    }

    const handleAccept = async () => {
        let body = {
            awardId: pendingWinner.awardId,
            employeeId: pendingWinner.employeeId,
            eventId: pendingWinner.eventId
        }
        await dispatch(handleWinnerAcceptance(body));
        await connection.send('AcceptWinner');
        dispatch(setPendingWinner(null));
        dispatch(setExpanded(false));
        await dispatch(fetchLastWinners(currentEvent.eventId));
        await dispatch(fetchAwards(currentEvent.eventId));


        handleNextWinner(currentAward?.remainingQuantity - 1);
    };

    const handleNextWinner = async (remaining = null) => {
        if (autoSpinEnabled && remaining != 0 && currentAward?.remainingQuantity > 0) {
            setTimeout(() => {
                spin();
            }, 2000);
        } else if (remaining == 0 || currentAward?.remainingQuantity === 0) {
            dispatch(setShowWinnersType('award'));
            dispatch(setShowWinnersPopup({show: true, type: 'award'}));
            dispatch(setAutoSpinEnabled(false));
            await connection.send('ShowWinners');
        }
    }

    const handleReject = async () => {
        await connection.send('RejectWinner');
        dispatch(setPendingWinner(null));
        dispatch(setExpanded(false));
        
        handleNextWinner();
    };

    return (
        <div className="pending-winner-container">
            <h4>Ganador Pendiente de Confirmación</h4>
            <div className="winner-card">
                <h3>{pendingWinner.firstName} {pendingWinner.lastName}</h3>
                <p>
                    <span className='text-warning'><FontAwesomeIcon icon={faTicket} /> #{pendingWinner.employeeCode}</span>
                    <span className="winner-code d-block">Departamento: {pendingWinner.department}</span>
                </p>
                <div className="winner-actions">
                    <button onClick={handleAccept} className="configBtn accept">
                        <FontAwesomeIcon icon={faCheck} /> Confirmar Ganador
                    </button>
                    <button onClick={handleReject} className={`configBtn reject ${config?.showRejectBtn ? 'd-block' : 'd-none'}`}>
                        <FontAwesomeIcon icon={faTimes} /> Rechazar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PendingWinner; 