import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicket, faCheck, faList, faTrash, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { toggleWinnersList, handleWinnerDeliver, handleWinnerDelete, fetchLastWinners } from '../../store/slices/raffleSlice';
import { fetchAwards } from '../../store/slices/awardsSlice';
import AlertMessage from '../common/AlertMessage';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { updateConfiguration } from '../../store/slices/configSlice';
import { utils as xlsxUtils, writeFile } from 'xlsx';
import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/lib/styles.css';
import 'tabulator-tables/dist/css/tabulator_semanticui.css';
import { reactFormatter } from '../../utils';

const WinnersList = () => {
    const dispatch = useDispatch();
    const { lastWinners, showWinnersList } = useSelector(state => state.raffle);
    const { currentEvent } = useSelector(state => state.events);
    const { currentAward } = useSelector(state => state.awards);

    const handleToggle = () => {
        dispatch(toggleWinnersList());
    };

    const ActionButtons = (props) => {
        const rowData = props.cell._cell.row.data;
        return (
            <div className="d-flex gap-2">
                {!rowData.isDelivered && (
                    <Button 
                        className="me-2" 
                        title="Marcar como entregado" 
                        color="success" 
                        size="sm" 
                        onClick={() => handleDeliverToWinner(rowData.winnerId, `${rowData.firstName} ${rowData.lastName}`)}
                    >
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                )}
                <Button 
                    title="Eliminar Registro" 
                    color="danger" 
                    size="sm" 
                    onClick={() => handleDeleteWinner(rowData.winnerId, `${rowData.firstName} ${rowData.lastName}`, rowData.awardId)}
                >
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
            </div>
        );
    };

    const StatusFormatter = (cell) => {
        const value = cell.getValue();
        return value ? 
            '<span class="badge bg-success"><i class="ri-check-fill me-1"></i> Entregado</span>' : 
            '<span class="badge bg-warning">Pendiente</span>';
    };


    const ActionRenderer = (props) => {
        const winner = props.cell._cell.row.data;
        return (
            <>
                {!winner.isDelivered && (
                    <>
                        <Button 
                            className="me-2" 
                    title="Marcar como entregado" 
                    color="success" 
                    size="sm" 
                    onClick={() => handleDeliverToWinner(winner.winnerId, `${winner.firstName} ${winner.lastName}`)}
                        >
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </>
                )}
                <Button 
                    title="Eliminar Registro" 
                    color="danger" 
                    size="sm" 
                    onClick={() => handleDeleteWinner(winner.winnerId, `${winner.firstName} ${winner.lastName}`, winner.awardId)}
                >
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
            </>
        );
      };

    const columns = [
        { 
            title: "Premio", 
            field: "awardName",
            headerFilter: true,
        },
        { 
            title: "Código", 
            field: "employeeCode",
            formatter: (cell) => `#${cell.getValue()}`,
            headerFilter: true,
        },
        { 
            title: "Nombre", 
            field: "firstName",
            formatter: (cell) => `${cell.getData().firstName} ${cell.getData().lastName}`,
            headerFilter: true,
        },
        { 
            title: "Estado", 
            field: "isDelivered",
            formatter: StatusFormatter,
            headerFilter: "tickCross",
        },
        { 
            title: "Acciones", 
            formatter: "html",
            formatterParams: {
                height: "100%"
            },
            width: 130,
            hozAlign: "center",
            headerSort: false,
            formatter: reactFormatter(<ActionRenderer />)
        }
    ];

    const tableData = useMemo(() => {
        return lastWinners.map(winner => ({...winner}));
      }, [lastWinners]);

    // Event listeners para los botones de acción
    React.useEffect(() => {
        const deliverHandler = (e) => handleDeliverToWinner(e.detail.winnerId, e.detail.name);
        const deleteHandler = (e) => handleDeleteWinner(e.detail.winnerId, e.detail.name, e.detail.awardId);
        
        document.addEventListener('deliverWinner', deliverHandler);
        document.addEventListener('deleteWinner', deleteHandler);
        
        return () => {
            document.removeEventListener('deliverWinner', deliverHandler);
            document.removeEventListener('deleteWinner', deleteHandler);
        };
    }, []);

    const options = {
        pagination: true,
        paginationSize: 10,
        paginationSizeSelector: [5, 10, 20, 50],
        movableColumns: true,
        groupBy: "awardName",
        placeholder: "No hay ganadores registrados",
    };

    const handleDeliverToWinner = async (winnerId, winnerName) => {
        const result = await Swal.fire({
            title: '¿Confirmar entrega?',
            text: `¿Está seguro que desea marcar como entregado el premio para ${winnerName}?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Sí, confirmar entrega',
            cancelButtonText: 'Cancelar'
        });
        
        if (result.isConfirmed) {
            try {
                Swal.fire({
                    title: 'Procesando...',
                    text: 'Por favor espere mientras se procesa la entrega',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });

                await dispatch(handleWinnerDeliver(winnerId));
                await dispatch(fetchLastWinners(currentEvent.eventId));

                Swal.fire({
                    title: '¡Éxito!',
                    text: 'Marcado como entregado correctamente',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                });
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: 'Error al marcar el premio como entregado',
                    icon: 'error'
                });
            }
        }
    };

    const handleDeleteWinner = async (winnerId, winnerName, awardId) => {
        const result = await Swal.fire({
            title: '¿Eliminar ganador?',
            text: `¿Está seguro que desea eliminar a ${winnerName} de la lista de ganadores?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        });

        if (result.isConfirmed) {
            try {
                Swal.fire({
                    title: 'Procesando...',
                    text: 'Por favor espere mientras se elimina el registro',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });

                await dispatch(handleWinnerDelete(winnerId));

                Swal.fire({
                    title: '¡Éxito!',
                    text: 'Eliminado correctamente',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                });
                await dispatch(fetchLastWinners(currentEvent.eventId));
                await dispatch(fetchAwards(currentEvent.eventId));
                if (awardId === currentAward.awardId) {
                    console.log("es igual")
                    await dispatch(updateConfiguration({
                        eventId: currentEvent.eventId,
                        awardId
                    }));
                }
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: 'Error al eliminar el ganador',
                    icon: 'error'
                });
            }
        }
    };

    const exportToExcel = () => {
        if (!lastWinners || lastWinners.length === 0) {
            toast.warning('No hay ganadores para exportar');
            return;
        }

        try {
            // Preparar los datos
            const excelData = lastWinners.map(winner => ({
                'Premio': winner.awardName,
                'Código de Empleado': winner.employeeCode,
                'Nombre': winner.firstName + ' ' + winner.lastName,
                'Departamento': winner.department,
                'Estado de Entrega': winner.isDelivered ? 'Entregado' : 'Pendiente',
                'Fecha y Hora': new Date(winner.wonAt).toLocaleString()
            }));

            // Crear el libro de trabajo
            const wb = xlsxUtils.book_new();
            const ws = xlsxUtils.json_to_sheet(excelData);

            // Ajustar el ancho de las columnas
            const columnWidths = [
                { wch: 20 }, // Premio
                { wch: 15 }, // Código
                { wch: 15 }, // Nombre
                { wch: 20 }, // Departamento
                { wch: 15 }, // Estado
                { wch: 30 }  // Fecha
            ];
            ws['!cols'] = columnWidths;

            // Definir estilos
            const headerStyle = {
                font: {
                    bold: true,
                    color: { rgb: "000000" },
                    sz: 12
                },
                fill: {
                    fgColor: { rgb: "EFEFEF" }
                }
            };

            const pendienteStyle = {
                font: {
                    color: { rgb: "FF0000" }, // Rojo
                    bold: true
                }
            };

            const entregadoStyle = {
                font: {
                    color: { rgb: "008000" }, // Verde
                    bold: true
                }
            };

            // Aplicar estilo a los encabezados
            const range = xlsxUtils.decode_range(ws['!ref']);
            for(let C = range.s.c; C <= range.e.c; ++C) {
                const address = xlsxUtils.encode_cell({r: 0, c: C});
                if(!ws[address]) continue;
                ws[address].s = headerStyle;
            }

            // Añadir la hoja al libro
            xlsxUtils.book_append_sheet(wb, ws, 'Ganadores');

            // Generar el archivo
            writeFile(
                wb, 
                `ganadores_${currentEvent?.eventName || 'evento'}_${new Date().toISOString().split('T')[0]}.xlsx`
            );

            toast.success('Archivo Excel generado exitosamente');
        } catch (error) {
            console.error('Error al exportar a Excel:', error);
            toast.error('Error al generar el archivo Excel');
        }
    };

    return (
        <div className="winners-section">
            <div className="winners-header">
                <div className="d-flex justify-content-between align-items-center" onClick={handleToggle}>
                    <h3 className='w-100'>
                        Lista de Ganadores
                        {lastWinners && lastWinners.length > 0 && (
                            <Button
                                className='ms-auto'
                                color="success"
                                size="sm"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    exportToExcel();
                                }}
                                title="Exportar a Excel"
                            >
                                <FontAwesomeIcon icon={faFileExcel} /> Exportar
                            </Button>
                        )}
                        <FontAwesomeIcon 
                            icon={faList} 
                            className={`ms-2 toggle-icon ${showWinnersList ? 'active' : ''}`}
                        />
                    </h3>
                </div>
            </div>

            <div className={`winners-content ${showWinnersList ? 'expanded' : ''}`}>
                {showWinnersList && (
                    <div className="winners-list">
                        {!currentEvent ? (
                            <AlertMessage 
                                type="warning"
                                text="black"
                                message="Seleccione un evento para ver los ganadores"
                            />
                        ) : !lastWinners || lastWinners.length === 0 ? (
                            <AlertMessage 
                                type="info"
                                text="black"
                                message="No hay ganadores registrados para este evento"
                            />
                        ) : (
                            <ReactTabulator
                                data={tableData}
                                columns={columns}
                                options={options}
                                layout={"fitColumns"}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default WinnersList; 