import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicket, faTimes, faTrophy, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { setShowWinnersPopup } from '../store/slices/raffleSlice';
import AlertMessage from './common/AlertMessage';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const AwardWinnersPopup = () => {
    const dispatch = useDispatch();
    const { showWinnersPopup, lastWinners, showWinnersType } = useSelector(state => state.raffle);
    const { currentAward } = useSelector(state => state.awards);
    const [index, setIndex] = useState(0);
    var currentAwardWinners = [];
    var totalAmount = 0;
    var labelName = '';

    if (!showWinnersPopup || !currentAward) return null;


    if (showWinnersType === 'award') {
        currentAwardWinners = lastWinners.filter(
            winner => winner.awardId === currentAward.awardId
        );
        totalAmount = currentAward.totalQuantity;
        labelName = 'de ' + currentAward.name;
    } else {
        currentAwardWinners = lastWinners;
        totalAmount = currentAwardWinners.length;
        labelName = 'del Evento';
    }

    const groupedLastWinners = currentAwardWinners.reduce((acc, winner, index) => {
        const page = Math.floor(index / 5);
        acc[page] = acc[page] || [];
        acc[page].push(winner);
        return acc;
    }, []);

    const handleClose = () => {
        dispatch(setShowWinnersPopup({show: false, type: showWinnersType}));
    };

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <>
            <div className="winners-popup-backdrop" onClick={handleClose}>
                <div className="winners-popup" onClick={e => e.stopPropagation()}>
                    <div className="winners-popup-header">
                        <h2>
                            <FontAwesomeIcon icon={faTrophy} className="trophy-icon" />
                            ¡Ganadores {labelName}!
                            <span className="winners-count">
                                ({currentAwardWinners.length})
                            </span>
                        </h2>
                    </div>
                    <div className="winners-popup-content">
                    {groupedLastWinners.length > 0 ? (
                    <OwlCarousel autoplay={true} dots={false} className='owl-theme' items={1} loop={true} margin={0}>
                      
                    {groupedLastWinners.map(page => (
                      <ul className='item'>
                        {page.map(w => (
                          <li key={w.employeeCode} className='listItem'>
                            <div className='userListItem'>
                              <div className='listItemTop'>
                                <h4>{w.firstName} {w.lastName}</h4>
                                <span><FontAwesomeIcon className='myIcon' icon={faTicket} />#{w.employeeCode}</span>
                              </div>
                              <div className='listItemBottom'>
                                <h5><span className='myBadget'>{w.awardName}</span></h5>
                                {/* <h6>#{w.code}</h6> */}
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                      ))}
                    </OwlCarousel>
                  ) : (
                    <AlertMessage 
                      type="info"
                      message="No hay ganadores para este premio"
                        />
                    )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AwardWinnersPopup; 