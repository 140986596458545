import { useDispatch, useSelector } from 'react-redux';
import { useSignalR } from './hooks/useSignalR';
import {
  setLastWinner,
  setShowWinner,
  setExpanding,
  setExpanded,
  fetchAttendants
} from './store/slices/raffleSlice';
import { fetchAwards } from './store/slices/awardsSlice';
import logo from './img/logo-1-white.png';
import fallback from './img/fallback.jpg';
import bgShape from './img/wave.svg';
import topShape from './img/top-shape.png';
import {Row, Col} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './css/App.css';
import { useState, useEffect, useRef  } from 'react';
import AwardsFeed from './components/AllAwards';
import CurrentAward from './components/CurrentAward';
import { WinnerScreen } from './components/WinnerScreen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicket } from '@fortawesome/free-solid-svg-icons';
import { Wheel } from 'react-custom-roulette';
import * as d3 from 'd3';
import { delay } from './utils';
import { fetchConfiguration } from './store/slices/configSlice';
import AlertMessage from './components/common/AlertMessage';
import AwardWinnersPopup from './components/AwardWinnersPopup';
import { setShowWinnersPopup } from './store/slices/raffleSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Carousel from 'react-bootstrap/Carousel';


const createUsers = (n) => {
  let data = [];
  let isFirst = true;
  for (let i = 0; i < n; i++) {
    data.push({option: i, style: { backgroundColor: isFirst ? '#cc208e' : '#6713d2', textColor: '#fff' }});
    isFirst = !isFirst;
  }
  return data;
} 

const data = createUsers(40);
console.log(data);/*[
  { option: '0', style: { backgroundColor: '#c02a45', textColor: 'black' } },
  { option: '1', style: { backgroundColor: '#fff', textColor: 'black' } },
  { option: '2', style: { backgroundColor: '#c02a45', textColor: 'black'} },
  { option: '3', style: { backgroundColor: '#fff', textColor: 'black' } },
]*/

function App() {
  const dispatch = useDispatch();
  const { 
    groupedLastWinners, 
    showWinner, 
    error,
    pendingWinner
  } = useSelector(state => state.raffle);
  const isConnected = useSelector(state => state.connection.isConnected);
  const connection = useSignalR();

  const { currentEvent } = useSelector(state => state.events);
  const { currentConfig, status } = useSelector(state => state.config);
  const { isExpanding, expanded } = useSelector(state => state.raffle);

  useEffect(() => {
    if (isConnected) {
      getConfiguration();
      connection.on('ConfigurationUpdated', (data) => {
        getConfiguration();
      });
      connection.on('ShowWinners', (type) => {
        console.log('ShowWinners', type);
        dispatch(setShowWinnersPopup({show: true, type}));
      });
      connection.on('HideWinners', () => {
        console.log('HideWinners');
        dispatch(setShowWinnersPopup({show: false, type: ''}));
      });
      connection.on('ReloadPage', () => {
        window.location.reload();
      });
    }
  }, [isConnected, dispatch]);

  useEffect(() => {
    if (status === 'idle') {
      if (currentConfig?.currentEventId > 0) {
        getAttendants(currentConfig.currentEventId);
        getAwards(currentConfig.currentEventId);
      }
    }
  }, [status, dispatch]);
  const getConfiguration = () => {
    dispatch(fetchConfiguration());
  }
  const getAttendants = (eventId) => {
    dispatch(fetchAttendants(eventId));
  }
  const getAwards = (eventId) => {
    dispatch(fetchAwards(eventId));
  }

  if (!isConnected || !currentEvent) {
    return (
      <div style={{minHeight: '100vh', backgroundImage: `url(${fallback})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}} id='fallback' alt="Waiting for event" />
      /*<div className="loading-screen">
        <img src={logo} alt="Waiting for event" />
        <h2>Esperando configuración del evento...</h2>
      </div>*/
    );
  }

  return (
    <div className={`App ${isExpanding || expanded ? 'expanded' : ''}`}>
      <header className="App-header">
        <Row>
          <div className='headerCont'>
            <div style={{marginLeft: -15}}>
              <img id='appLogo' src={logo}/>
            </div>
            <div>
              <h1 id='partyName'>{currentEvent.name}</h1> 
            </div>
          </div>
        </Row>
      </header>
      <main>
        <div className='myCont'>
          <Row className='mainWidgetsCont'>
            <Col lg={7} className=''>
              <CurrentAward connection={connection} fetchConfiguration={getConfiguration} fetchAttendants={getAttendants} fetchAwards={getAwards} spinDuration={(currentConfig?.spiningTime || 5) * 1000} />
            </Col>
              <Col lg={5} className='d-none d-lg-block'>
              <div className='mainWidgets mainWidgetB'>
                <div className='widgetHeader'>
                  <h3>Ultimos Ganadores</h3>
                </div>
                <div className='widgetBodyB'>
                  {groupedLastWinners.length > 0 ? (
                    <Carousel>
                      
                    {groupedLastWinners.map(page => (
                      <Carousel.Item key={crypto.randomUUID()} interval={2000}>
                        <ul className='item'>
                          {page.map(w => (
                            <li key={w.winnerId} className='listItem'>
                            <div className='userListItem'>
                              <div className='listItemTop'>
                                <h4>{w.firstName} {w.lastName}</h4>
                                <span><FontAwesomeIcon className='myIcon' icon={faTicket} />#{w.employeeCode}</span>
                              </div>
                              <div className='listItemBottom'>
                                <h5><span className='myBadget'>{w.awardName}</span></h5>
                                {/* <h6>#{w.code}</h6> */}
                              </div>
                            </div>
                          </li>
                          ))}
                        </ul>
                      </Carousel.Item>
                    ))}
                    </Carousel>
                  ) : (
                    <AlertMessage 
                      type="info"
                      message="No hay ganadores registrados para este evento"
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </main>
      <div className='footer'>
        <div className='myCont'>
          <Row>
            <Col sm={12}>
              <div id='allRewardsWidget' className="ticker">
                 <div className="ticker__field">
                    <AwardsFeed>

                    </AwardsFeed>
                  </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <WinnerScreen winner={pendingWinner} isVisible={showWinner}></WinnerScreen>
      <AwardWinnersPopup />
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        style={{ zIndex: 9999 }}
      />
    </div>
  );
}

export default App;
