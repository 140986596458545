import { configureStore } from '@reduxjs/toolkit';
import awardsReducer from './slices/awardsSlice';
import raffleReducer from './slices/raffleSlice';
import eventReducer from './slices/eventSlice';
import connectionReducer from './slices/connectionSlice';
import configReducer from './slices/configSlice';
export const store = configureStore({
  reducer: {
    awards: awardsReducer,
    raffle: raffleReducer,
    events: eventReducer,
    connection: connectionReducer,
    config: configReducer
  }
});

export default store;
