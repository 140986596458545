import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicket, faCheck, faList, faTimes } from '@fortawesome/free-solid-svg-icons';
import { fetchAttendants, toggleAttendantAssistance, toggleAttendantsList } from '../../store/slices/raffleSlice';
import { fetchAwards } from '../../store/slices/awardsSlice';
import AlertMessage from '../common/AlertMessage';
import { Table, Badge, Button } from 'reactstrap';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { updateConfiguration } from '../../store/slices/configSlice';
import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/lib/styles.css';
import 'tabulator-tables/dist/css/tabulator_semanticui.css';
import { reactFormatter } from '../../utils';

const AttendantsList = ({updateConfiguration}) => {
    const dispatch = useDispatch();
    const { attendants, showAttendantsList } = useSelector(state => state.raffle);
    const { currentEvent } = useSelector(state => state.events);

    const handleToggle = () => {
        dispatch(toggleAttendantsList());
    };
    useEffect(() => {
        dispatch(fetchAttendants(currentEvent.eventId));
    }, [dispatch, currentEvent.eventId]);


    const handleToggleAttendantAssistance = async (employeeId, status) => {
        const result = await Swal.fire({
            title: '¿Confirmar acción?',
            text: `¿Está seguro que desea marcar este participante como ${status ? '"asistido"' : '"no asistido"'}?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Sí, confirmar',
            cancelButtonText: 'Cancelar'
        });
        
        if (result.isConfirmed) {
            try {
                Swal.fire({
                    title: 'Procesando...',
                    text: 'Por favor espere mientras se procesa la solicitud',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });

                await dispatch(toggleAttendantAssistance({employeeId, isAssisted: status, eventId: currentEvent.eventId}));
                await dispatch(fetchAttendants(currentEvent.eventId));

                Swal.fire({
                    title: '¡Éxito!',
                    text: 'Solicitud procesada correctamente',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                });
                window.location.reload();
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: 'Error al procesar la solicitud',
                    icon: 'error'
                });
            }
        }
    };

    const ActionRenderer = (props) => {
        const attendant = props.cell._cell.row.data;
        return (
            <>
                {!attendant.isAssisted && (
                    <Button 
                        className="me-2" 
                        title="Marcar como asistido" 
                        color="success" 
                        size="sm" 
                        onClick={() => handleToggleAttendantAssistance(attendant.employeeId, true)}
                    >
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                )}
                <Button 
                    title="Marcar como no asistido" 
                    color="danger" 
                    size="sm" 
                    onClick={() => handleToggleAttendantAssistance(attendant.employeeId, false)}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </>
        );
    };

    const StatusFormatter = (cell) => {
        const value = cell.getValue();
        return value ? 
            '<span class="badge bg-success"><i class="ri-check-fill me-1"></i> En el Evento</span>' : 
            '<span class="badge bg-warning">No Asistió</span>';
    };

    const columns = [
        { 
            title: "Código", 
            field: "employeeCode",
            formatter: (cell) => `#${cell.getValue()}`,
            headerFilter: true,
        },
        { 
            title: "Nombre", 
            field: "firstName",
            formatter: (cell) => `${cell.getData().firstName} ${cell.getData().lastName}`,
            headerFilter: true,
        },
        { 
            title: "Estado", 
            field: "isAssisted",
            formatter: StatusFormatter,
            headerFilter: "tickCross",
        },
        { 
            title: "Acciones", 
            formatter: reactFormatter(<ActionRenderer />),
            width: 130,
            hozAlign: "center",
            headerSort: false
        }
    ];

    const tableData = useMemo(() => {
        return attendants?.map(attendant => ({...attendant})) || [];
    }, [attendants]);

    const options = {
        pagination: true,
        paginationSize: 10,
        paginationSizeSelector: [5, 10, 20, 50],
        movableColumns: true,
        placeholder: "No hay participantes registrados",
    };

    return (
        <div className="winners-section">
            <div className="winners-header" onClick={handleToggle}>
                <h3>
                    Lista de Participantes
                    <FontAwesomeIcon 
                        icon={faList} 
                        className={`ms-auto toggle-icon ${showAttendantsList ? 'active' : ''}`}
                    />
                </h3>
            </div>

            <div className={`winners-content ${showAttendantsList ? 'expanded' : ''}`}>
                {showAttendantsList && (
                    <div className="winners-list">
                        {!currentEvent ? (
                            <AlertMessage 
                                type="warning"
                                text="black"
                                message="Seleccione un evento para ver los participantes"
                            />
                        ) : !attendants || attendants.length === 0 ? (
                            <AlertMessage 
                                type="info"
                                text="black"
                                message="No hay participantes registrados para este evento"
                            />
                        ) : (
                            <ReactTabulator
                                data={tableData}
                                columns={columns}
                                options={options}
                                layout={"fitColumns"}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AttendantsList; 