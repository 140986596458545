import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faExclamationTriangle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import '../../css/AlertMessage.css';

const AlertMessage = ({ type = 'info', message, className = '', text = 'white' }) => {
    const getIcon = () => {
        switch (type) {
            case 'warning':
                return faExclamationTriangle;
            case 'success':
                return faCheckCircle;
            default:
                return faInfoCircle;
        }
    };

    return (
        <div className={`alert-message ${type} ${className}`}>
            <FontAwesomeIcon icon={getIcon()} className="alert-icon" />
            <p style={{ color: text }}>{message}</p>
        </div>
    );
};

export default AlertMessage; 