import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { fetchEvents, setCurrentEvent } from '../../store/slices/eventSlice';
import { fetchLastWinners } from '../../store/slices/raffleSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faRotate } from '@fortawesome/free-solid-svg-icons';

const EventSelector = ({disabled, handlePublish, connection, handleUnpublish, handleResetWinners, expanded}) => {
    const dispatch = useDispatch();
    const { events, currentEvent } = useSelector(state => state.events);

    useEffect(() => {
        dispatch(fetchEvents());
    }, [dispatch]);

    const handleEventChange = (eventId) => {
        const selectedEvent = events.find(e => e.eventId === parseInt(eventId));
        dispatch(setCurrentEvent(selectedEvent));
            
        selectedEvent?.eventId && dispatch(fetchLastWinners(selectedEvent.eventId));
    };

    const handlePublishEvent = async () => {
        if (connection && currentEvent) {
            try {
                handlePublish(currentEvent.eventId);
            } catch(e) {
                console.error('Error publishing event:', e);
            }
        }
    };

    const handleUnpublishEvent = () => {
        if (connection && currentEvent) {
            try {
                handleUnpublish(currentEvent.eventId);
            } catch(e) {
                console.error('Error publishing event:', e);
            }
        }
    };

    return (
        <div className="config-section">
            <FormGroup>
                <div className="config-section-title d-flex align-items-center">
                    <Label for="eventSelect">Evento Actual</Label>
                    <a onClick={handleUnpublishEvent} href="#" className={`text-danger ms-auto config-section-title-link ${currentEvent && currentEvent?.isPublished ? 'd-block' : 'd-none'}`}>
                        <FontAwesomeIcon className="me-2" icon={faXmark} />
                        Despublicar
                    </a>
                    <a onClick={handleResetWinners} href="#" className={`text-danger ms-3 config-section-title-link ${currentEvent && currentEvent?.isPublished && !expanded ? 'd-block' : 'd-none'}`}>
                        <FontAwesomeIcon className="me-2" icon={faRotate} />
                        Reiniciar Evento
                    </a>
                </div>
                <Input
                    type="select"
                    name="eventSelect"
                    id="eventSelect"
                    disabled={disabled}
                    value={currentEvent?.eventId || ''}
                    onChange={(e) => handleEventChange(e.target.value)}
                >
                    <option value="">Seleccione un evento</option>
                    {events.map(event => (
                        <option key={event.eventId} value={event.eventId}>
                            {event.name}
                        </option>
                    ))}
                </Input>
            </FormGroup>
            {currentEvent && !currentEvent.isPublished && (
                <button 
                    className="configBtn primary"
                    onClick={handlePublishEvent}
                >
                    Publicar Evento
                </button>
            )}
        </div>
    );
};

export default EventSelector; 