import React from 'react';
import { useDispatch } from 'react-redux';
import { handleWinnerAcceptance, handleWinnerRejection } from '../store/slices/raffleSlice';
import '../css/App.css';
import winnerRay from '../img/winner-ray.png';
import winnerShadow from '../img/winner-shadow.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicket, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

export const WinnerScreen = (props) => {
    const dispatch = useDispatch();


    return (props.isVisible ?
        <>
            <div className={props.isVisible ? 'myBackdrop show' : 'myBackdrop'}></div>
            <img src={winnerRay} className={props.isVisible ? 'winnerRay show' : 'winnerRay'}/>
            <img src={winnerShadow} className={props.isVisible ? 'winnerShadow' : 'winnerShadow'}/>
            <div id='winnerScreenCont'>
                <div id='winnerScreen'>
                    <h2>¡Felicidades!</h2>
                    <h1>{props.winner.firstName} {props.winner.lastName}
                        <span><FontAwesomeIcon className='myIconB' icon={faTicket} />#{props.winner.ticket}</span>
                    </h1>
                    <p><span className='myBadget'>{props.winner.code}</span></p>
                </div>
            </div>
        </>
        : null
    );
};