import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Input } from 'reactstrap';
import { useSignalR } from './hooks/useSignalR';
import EventSelector from './components/config/EventSelector';
import AwardSelector from './components/config/AwardSelector';
import WinnersList from './components/config/WinnersList';
import AttendantsList from './components/config/AttendantsList';
import PendingWinner from './components/config/PendingWinner';
import './css/config.css';
import { setAutoSpinEnabled, resetWinners, hideWinner, setExpanded, setShowWinnersPopup, setShowWinnersType } from './store/slices/raffleSlice';
import { fetchConfiguration, updateConfiguration } from './store/slices/configSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

function Config() {
    const isConnected = useSelector(state => state.connection.isConnected);
    const connection = useSignalR();
    const { currentEvent } = useSelector(state => state.events);
    const { lastWinners, isAutoSpinning, showWinner, autoSpinEnabled, showWinnersPopup, showWinnersType, pendingWinner } = useSelector(state => state.raffle);
    const { currentAward } = useSelector(state => state.awards);
    const dispatch = useDispatch();
    const { currentConfig } = useSelector(state => state.config);
    const { expanded } = useSelector(state => state.raffle);
    const [raffleActionsEnabled, setRaffleActionsEnabled] = useState(true);
    const [awardIsEmpty, setAwardIsEmpty] = useState(false);
    const [currentWinners, setCurrentWinners] = useState(0);
    useEffect(() => {
        dispatch(fetchConfiguration());
    }, [dispatch]);

    useEffect(() => {
        if (showWinnersType === 'award') {
            setCurrentWinners(currentAward?.remainingQuantity - currentAward?.totalQuantity);
        } else {
            setCurrentWinners(lastWinners?.length);
        }
    }, [showWinnersType, lastWinners, currentAward?.remainingQuantity]);

    useEffect(() => {
        if (connection) {
            connection.on('ReloadPage', () => {
                window.location.reload();
            });
        }
    }, [connection]);

    useEffect(() => {
        setAwardIsEmpty(currentAward?.remainingQuantity === 0);
        if (currentAward?.remainingQuantity === 0) {
            handleShowWinners();
        }
    }, [currentAward?.remainingQuantity]);

    const handlePublish = async (eventId) => {
        const result = await Swal.fire({
            title: '¿Publicar Evento?',
            text: `¿Está seguro que desea publicar el evento "${currentEvent.name}"?.`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Sí, publicar',
            cancelButtonText: 'Cancelar'
        });

        if (result.isConfirmed) {
            try {
                Swal.fire({
                    title: 'Procesando...',
                    text: 'Por favor espere mientras se publica el evento',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });

                await dispatch(updateConfiguration({
                    eventId,
                    awardId: null // Reset award when changing event
                }));
                await dispatch(fetchConfiguration()); // Recargar la lista de eventos
                    Swal.fire({
                        title: '¡Éxito!',
                        text: 'Evento publicado correctamente',
                        icon: 'success',
                        timer: 2000,
                        showConfirmButton: false
                    });
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: 'Error al publicar el evento',
                    icon: 'error'
                });
            }
        }
    };

    const handleUnpublish = async (eventId) => {
        const result = await Swal.fire({
            title: '¿Despublicar Evento?',
            text: `¿Está seguro que desea despublicar el evento "${currentEvent.name}"?.`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Sí, despublicar',
            cancelButtonText: 'Cancelar'
        });

        if (result.isConfirmed) {
            try {
                Swal.fire({
                    title: 'Procesando...',
                    text: 'Por favor espere mientras se despublica el evento',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });

                await dispatch(updateConfiguration({
                    eventId: null,
                    awardId: null // Reset award when changing event
                }));
                await dispatch(fetchConfiguration()); // Recargar la lista de eventos
                    Swal.fire({
                        title: '¡Éxito!',
                        text: 'Evento despublicado correctamente',
                        icon: 'success',
                        timer: 2000,
                        showConfirmButton: false
                    });
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: 'Error al despublicar el evento',
                    icon: 'error'
                });
            }
        }
    };

    const handleUpdateConfiguration = async () => {
        await dispatch(updateConfiguration({
            eventId: currentEvent.eventId,
            awardId: currentAward?.awardId
        }));
    };

    const handleAwardChange = async (awardId) => {
        try {
            await dispatch(updateConfiguration({
                eventId: currentConfig?.currentEventId,
                awardId
            }));
        } catch (error) {
            console.error('Error updating award configuration:', error);
        }
    };

    const spin = async () => {

        if (isConnected && currentEvent && currentAward) {
            try {
                dispatch(setExpanded(true));
                await connection.send('Start');
            } catch(e) {
                console.error(e);
            }
        }
    };

    const handleResetWinners = async () => {
        let inputCode = '';
        let code = currentConfig.restartEventPin;
        const result = await Swal.fire({
            title: '¿Reiniciar Evento?',
            text: '¿Está seguro que desea reiniciar todo lo relacionado con este evento? Esta acción no se puede deshacer. Ingrese el pin de administrador.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Sí, reiniciar',
            cancelButtonText: 'Cancelar',
            input: 'text',
            customClass: {
                validationMessage: 'my-validation-message'
            },
            preConfirm: (value) => {
                if (!value) {
                    Swal.showValidationMessage(
                        '<i class="fa fa-info-circle"></i> Ingrese el pin de administrador'
                    )
                } else if (value !== code) {
                    Swal.showValidationMessage(
                        '<i class="fa fa-info-circle"></i> El pin es incorrecto'
                    )
                } else {
                    inputCode = value;
                }
            }
        });

        if (result.isConfirmed) {
            try {
                Swal.fire({
                    title: 'Procesando...',
                    text: 'Por favor espere mientras se reinicia el evento',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });

                let res =  await dispatch(resetWinners({
                    eventId: currentEvent.eventId
                }));
                if (res) {
                    await connection.send('ReloadPage');
                    Swal.fire({
                        title: '¡Éxito!',
                        text: 'Evento reiniciado correctamente',
                        icon: 'success',
                        timer: 2000,
                        showConfirmButton: false
                    });
                }else{
                    Swal.fire({
                        title: 'Error',
                        text: 'Error al reiniciar el evento',
                        icon: 'error'
                    });
                }


            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: 'Error al reiniciar el evento',
                    icon: 'error'
                });
            }
        }
    };

    const handleAutoSpinToggle = (e) => {
        const isEnabled = e.target.checked;
        dispatch(setAutoSpinEnabled(isEnabled));
        
        // Si se activa el auto-spin y hay un premio seleccionado con cantidad disponible,
        // iniciar el sorteo automáticamente
        /*if (isEnabled && currentAward?.remainingQuantity > 0) {
            spin();
        }*/
    };

    const handleHideWinner = () => {
        dispatch(hideWinner());
    };

    const handleCancel = async () => {
        if (isConnected) {
            try {
                await connection.send('Reset');
            } catch(e) {
                console.error(e);
            }
        }
        dispatch(setAutoSpinEnabled(false));
        dispatch(setExpanded(false));
        setRaffleActionsEnabled(true);
    };

    const handleShowWinners = async () => {
        if (isConnected && connection && currentAward) {
            dispatch(setShowWinnersPopup({show: true, type: showWinnersType}));
            await connection.send('ShowWinners', showWinnersType);
            setRaffleActionsEnabled(false);
        }
    };
    
    const handleCloseWinnersPopup = async () => {
        if (connection) {
            dispatch(setShowWinnersPopup({show: false, type: showWinnersType}));
            await connection.send('HideWinners');
            setRaffleActionsEnabled(true);
        }
    };
    
    const handleShowWinnersType = (type) => {
        dispatch(setShowWinnersType(type));
    };

    return (
        <SimpleBar style={{ maxHeight: '100vh' }} className='configApp'>
            <Container className=''>
                <Row id='configCont'>
                    <Col lg={12} className="mx-auto">
                        <div className="config-panel">
                            <h1 className='configTitle'>Configuración</h1>
                            
                            <EventSelector handleResetWinners={handleResetWinners} expanded={expanded} disabled={expanded || !raffleActionsEnabled || pendingWinner} handlePublish={handlePublish} handleUnpublish={handleUnpublish} connection={connection} />
                            {currentEvent?.isPublished && (
                                <AwardSelector disabled={expanded || !raffleActionsEnabled || pendingWinner} handleChange={handleAwardChange} />
                            )}
                            
                            <PendingWinner connection={connection} spin={spin} config={currentConfig} />

                            {currentEvent?.isPublished && currentAward && !pendingWinner && (
                                <>
                                    <div className="config-section">
                                        <h3>Control de Sorteo</h3>
                                        <div className="button-group">
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="autoSpinCheck"
                                                    checked={autoSpinEnabled}
                                                    onChange={handleAutoSpinToggle}
                                                    disabled={isAutoSpinning}
                                                />
                                                <label className="form-check-label text-dark" htmlFor="autoSpinCheck">
                                                    Sorteo Automático
                                                </label>
                                            </div>
                                            <button 
                                                onClick={spin} 
                                                className={`configBtn primary ${!raffleActionsEnabled || awardIsEmpty || expanded ? 'd-none' : ''}`}
                                                disabled={isAutoSpinning}
                                            >
                                                Iniciar Sorteo
                                            </button>

                                            {showWinner && !autoSpinEnabled && (
                                                <button 
                                                    onClick={handleHideWinner}
                                                    className='configBtn secondary'
                                                >
                                                    Ocultar Ganador Actual
                                                </button>
                                            )}
                                            
                                            <button 
                                                onClick={handleCancel} 
                                                className={`configBtn danger ${!expanded ? 'd-none' : ''}`}
                                            >
                                                Cancelar
                                            </button>
                                            <Row className="mt-2 w-100">
                                                <Col lg={6} xs={12} className="">
                                                    <Input disabled={showWinnersPopup} onClick={() => handleShowWinnersType('award')} checked={showWinnersType === 'award'} className="form-check-input" type="radio" name="inlineRadioOptions" id="currentAwardWinners" />
                                                    <label className="form-check-label text-dark" htmlFor="currentAwardWinners">Ganadores del Premio Seleccionado</label>
                                                </Col>
                                                <Col lg={6} xs={12} className="">
                                                    <Input disabled={showWinnersPopup} onClick={() => handleShowWinnersType('event')} checked={showWinnersType === 'event'} className="form-check-input" type="radio" name="inlineRadioOptions" id="currentEventWinners" />
                                                    <label className="form-check-label text-dark" htmlFor="currentEventWinners">Ganadores del Evento</label>
                                                </Col>
                                            </Row>
                                            <button 
                                                className={`configBtn info ${showWinnersPopup || expanded ? 'd-none' : ''}`}
                                                onClick={handleShowWinners}
                                                disabled={currentWinners === 0}
                                            >
                                                <FontAwesomeIcon icon={faTrophy} /> Ver Ganadores {showWinnersType === 'award' ? 'del Premio Seleccionado' : 'del Evento'}
                                            </button>
                                            <button 
                                                className={`configBtn info ${!showWinnersPopup ? 'd-none' : ''}`}
                                                onClick={handleCloseWinnersPopup}
                                            >
                                                <FontAwesomeIcon icon={faTimes} /> Ocultar Ganadores
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                            {currentEvent?.eventId && (
                                <WinnersList />
                            )}
                            {currentEvent?.eventId && (
                                <AttendantsList updateConfiguration={handleUpdateConfiguration} />
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </SimpleBar>
    );
}

export default Config;
