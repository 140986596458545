import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { eventService } from '../../services/api';

export const fetchEvents = createAsyncThunk(
  'events/fetchEvents',
  async () => {
    return await eventService.getEvents();
  }
);

export const fetchCurrentEvent = createAsyncThunk(
  'events/fetchCurrentEvent',
  async () => {
    return await eventService.getCurrentEvent();
  }
);

const eventSlice = createSlice({
  name: 'events',
  initialState: {
    events: [],
    currentEvent: null,
    status: 'idle',
    error: null
  },
  reducers: {
    setCurrentEvent: (state, action) => {
      state.currentEvent = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.events = action.payload;
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchCurrentEvent.fulfilled, (state, action) => {
        state.currentEvent = action.payload;
      });
  }
});

export const { setCurrentEvent } = eventSlice.actions;
export default eventSlice.reducer; 