import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { configService } from '../../services/api';
import { fetchCurrentEvent } from './eventSlice';
import { fetchAwards, fetchCurrentAward } from './awardsSlice';
import { fetchLastWinners } from './raffleSlice';

export const fetchConfiguration = createAsyncThunk(
    'config/fetchConfiguration',
    async (_, { dispatch }) => {
        const config = await configService.getConfiguration();
        
        if (config?.currentEventId > 0) {
            dispatch(fetchCurrentEvent());
            
            if (config?.currentAwardId > 0) {
                await dispatch(fetchAwards(config.currentEventId));
                await dispatch(fetchCurrentAward(config.currentAwardId));
            }
            dispatch(fetchLastWinners(config.currentEventId));
        }
        
        return config;
    }
);

export const updateConfiguration = createAsyncThunk(
  'config/updateConfiguration',
  async ({ eventId, awardId }) => {
    return await configService.updateConfiguration(eventId, awardId);
  }
);

const initialState = {
  currentConfig: null,
  status: 'not-connected',
  error: null
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfiguration: (state, action) => {
      state.currentConfig = action.payload;
      if (action.payload.eventId) {
        state.status = 'idle';
      }else{
        state.status = 'not-connected';
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfiguration.pending, (state) => {
        state.status = 'not-connected';
      })
      .addCase(fetchConfiguration.fulfilled, (state, action) => {
        state.currentConfig = action.payload;
        if (action.payload.currentEventId) {
          state.status = 'idle';
        }else{
          state.status = 'not-connected';
        }
      })
      .addCase(fetchConfiguration.rejected, (state, action) => {
        state.status = 'not-connected';
        state.error = action.error.message;
      });
  }
});

export const { setConfiguration } = configSlice.actions;
export default configSlice.reducer; 