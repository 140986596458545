import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './css/index.css'
import App from './App'
import Config from './Config'
import { Provider } from 'react-redux';
import { store } from './store/store';


ReactDOM.render(<Provider store={store}>
    <BrowserRouter>
      <Routes> 
        <Route exact path='/' element={<App />}/>
        <Route path='/config' element={<Config />}/>
      </Routes>
    </BrowserRouter>
  </Provider>, document.getElementById('root'))