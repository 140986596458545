import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { raffleService } from '../../services/api';

export const fetchAttendants = createAsyncThunk(
  'raffle/fetchAttendants',
  async (eventId) => {
    return await raffleService.getAttendants(eventId);
  }
);

export const fetchLastWinners = createAsyncThunk(
  'raffle/fetchLastWinners',
  async (eventId) => {
    return await raffleService.getLastWinners(eventId);
  }
);

export const handleWinnerAcceptance = createAsyncThunk(
  'raffle/handleWinnerAcceptance',
  async (winner, { dispatch }) => {
    const response = await raffleService.acceptWinner(winner);
    if (response) {
      await dispatch(fetchLastWinners(winner.eventId));
      return winner;
    }
    throw new Error('Error al aceptar el ganador');
  }
);

export const handleWinnerDeliver = createAsyncThunk(
  'raffle/handleWinnerDeliver',
  async (winnerId, { dispatch, getState }) => {
    const response = await raffleService.deliverWinnerAward(winnerId);
    throw new Error('Error al entregar el premio');
  }
);

export const handleWinnerDelete = createAsyncThunk(
  'raffle/handleWinnerDelete',
  async (winnerId, { dispatch, getState }) => {
    let eventId = getState().events.currentEvent.eventId;
    console.log('eventId', eventId);
    const response = await raffleService.deleteWinner(winnerId);
    throw new Error('Error al eliminar el ganador');
  }
);


export const toggleAttendantAssistance = createAsyncThunk(
  'raffle/toggleAttendantAssistance',
  async ({employeeId, isAssisted, eventId}, { dispatch }) => {
    const response = await raffleService.toggleAttendantAssistance(employeeId, isAssisted, eventId);
    throw new Error('Error al entregar el premio');
  }
);

export const startAutoSpin = createAsyncThunk(
  'raffle/startAutoSpin',
  async ({ eventId, awardId }, { dispatch }) => {
    const response = await raffleService.getNextWinner(eventId, awardId);
    if (response.success) {
      dispatch(setPendingWinner(response.winner));
      return response.winner;
    }
    throw new Error('No hay más ganadores disponibles');
  }
);

export const resetWinners = createAsyncThunk(
  'raffle/resetWinners',
  async ({eventId}, { dispatch }) => {
    try {
      const response = await raffleService.resetWinners(eventId);
      if (response) {
        await dispatch(fetchLastWinners(eventId));
        return { eventId };
      }
      throw new Error('Error al resetear los ganadores');
    } catch (error) {
      throw new Error('Error al resetear los ganadores');
    }
  }
);

const initialState = {
  attendants: [],
  lastWinners: [],
  groupedLastWinners: [],
  pendingWinner: null,
  isExpanding: false,
  expanded: false,
  showWinner: false,
  autoSpinEnabled: false,
  isAutoSpinning: false,
  status: 'idle',
  error: null,
  showWinnersList: false,
  showWinnersPopup: false,
  showWinnersType: 'award',
  showAttendantsList: false,
  currentAwardWinners: [],
};

export const raffleSlice = createSlice({
  name: 'raffle',
  initialState,
  reducers: {
    setExpanding: (state, action) => {
      state.isExpanding = action.payload;
    },
    setExpanded: (state, action) => {
      state.expanded = action.payload;
    },
    setPendingWinner: (state, action) => {
      state.pendingWinner = action.payload;
    },
    setAutoSpinEnabled: (state, action) => {
      state.autoSpinEnabled = action.payload;
    },
    setAutoSpinning: (state, action) => {
      state.isAutoSpinning = action.payload;
    },
    setShowWinner: (state, action) => {
      state.showWinner = action.payload;
    },
    hideWinner: (state) => {
      state.showWinner = false;
    },
    toggleWinnersList: (state) => {
      state.showWinnersList = !state.showWinnersList;
    },
    toggleAttendantsList: (state) => {
      state.showAttendantsList = !state.showAttendantsList;
    },
    setShowWinnersPopup: (state, action) => {
      state.showWinnersPopup = action.payload.show;
      state.showWinnersType = action.payload.type;
    },
    setShowWinnersType: (state, action) => {
      state.showWinnersType = action.payload;
    },
    setShowAttendantsList: (state, action) => {
      state.showAttendantsList = action.payload;
    },
    setCurrentAwardWinners: (state, action) => {
      state.currentAwardWinners = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAttendants.fulfilled, (state, action) => {
        state.attendants = action.payload;
        state.status = 'succeeded';
      })
      .addCase(fetchAttendants.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = 'failed';
      })
      .addCase(fetchLastWinners.fulfilled, (state, action) => {
        // Group winners into arrays of 3

        const groupedLastWinners = action.payload.reduce((acc, winner, index) => {
          const page = Math.floor(index / 4);
          acc[page] = acc[page] || [];
          acc[page].push(winner);
          return acc;
      }, []);
        state.groupedLastWinners = groupedLastWinners;
        state.lastWinners = action.payload;
        state.status = 'succeeded';
      })
      .addCase(handleWinnerAcceptance.fulfilled, (state, action) => {
        state.pendingWinner = null;
        state.showWinner = false;
      })
      .addCase(startAutoSpin.fulfilled, (state) => {
        state.showWinner = true;
      })
      .addCase(startAutoSpin.rejected, (state) => {
        state.isAutoSpinning = false;
      })
      .addCase(resetWinners.fulfilled, (state, action) => {
        state.lastWinners = state.lastWinners.filter(
          winner => 
            winner.eventId !== action.payload.eventId || 
            winner.awardId !== action.payload.awardId
        );
        state.status = 'succeeded';
      })
      .addCase(resetWinners.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = 'failed';
      });
  }
});

export const {
  setExpanding,
  setExpanded,
  setPendingWinner,
  setAutoSpinEnabled,
  setAutoSpinning,
  setShowWinner,
  hideWinner,
  toggleWinnersList,
  toggleAttendantsList,
  setShowWinnersPopup,
  setShowWinnersType,
  setCurrentAwardWinners
} = raffleSlice.actions;

export default raffleSlice.reducer;
