import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as d3 from 'd3';
import { setShowWinner, setExpanding, setExpanded, fetchLastWinners } from '../store/slices/raffleSlice';
import { reduceRemainingQuantity } from '../store/slices/awardsSlice';
import '../css/App.css';
import AlertMessage from './common/AlertMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faTicket } from '@fortawesome/free-solid-svg-icons';
import { SlotMachine } from 'react-slot-machine-text';

const winnerSound = new Audio('sounds/winner.wav');

// Precarga de sonidos
const preloadSounds = () => {
    winnerSound.load();
};

const CurrentAward = ({
    fetchConfiguration, 
    fetchAttendants, 
    fetchAwards, 
    connection,
    spinDuration = 10000
}) => {
    const dispatch = useDispatch();
    const { isExpanding, expanded, attendants } = useSelector(state => state.raffle);
    const { currentAward } = useSelector(state => state.awards);
    const { currentEvent } = useSelector(state => state.events);
    const { lastWinners } = useSelector(state => state.raffle);
    const d3Container = useRef(null);
    const [awardIsEmpty, setAwardIsEmpty] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);
    const isCancelledRef = useRef(false);
    const isExpandingRef = useRef(false);
    
    useEffect(() => {
        setAwardIsEmpty(currentAward?.remainingQuantity === 0);
        if (currentAward?.remainingQuantity === 0) {
            
        }
    }, [currentAward?.remainingQuantity]);

    useEffect(() => {
        console.log('attendants', attendants);
    }, [attendants]);

    useEffect(() => {
        isCancelledRef.current = isCancelled;
        resetSpinner();
    }, [isCancelled]);

    useEffect(() => {
        if (connection) {
            connection.on('StartSpin', d => {
                spin();
            });
            
            connection.on('ResetSpin', async d => {
                if (isExpandingRef.current) {
                    while (isExpandingRef.current) {
                        await new Promise(resolve => setTimeout(resolve, 100));
                    }
                }
                unExpandItems();
                resetSpinner();
                setIsCancelled(true);
            });

            connection.on('WinnerAccepted', winner => {
                unExpandItems();
                resetSpinner();
                setShowWinner(false);
                fetchAwards(currentEvent.eventId);
                dispatch(fetchLastWinners(currentEvent.eventId));
                document.getElementById('winnerInfo').classList.add('d-none');
            });

            connection.on('WinnerRejected', winnerId => {
                unExpandItems();
                resetSpinner();
                setShowWinner(false);
                document.getElementById('winnerInfo').classList.add('d-none');
            });

            return () => {
                connection.off('StartSpin');
                connection.off('ResetSpin');
                connection.off('WinnerAccepted');
                connection.off('WinnerRejected');
            };
        }
    }, [connection, fetchConfiguration, fetchAttendants]);

    useEffect(() => {
        const contD3 = d3.select(d3Container.current);
        contD3.selectAll('div')
            .data([awardIsEmpty ? 'Agotado' : '¡Comenzar!'])
            .enter()
            .append('h1')
            .text(a => a);
    }, [currentAward, awardIsEmpty ]);

    // Precargar sonidos al montar el componente
    useEffect(() => {
        preloadSounds();
    }, []);

    const expandWidgetA = useCallback(async () => {
        dispatch(setExpanding(true));
        isExpandingRef.current = true;
        document.querySelectorAll('.attendantList h1').forEach(e => e.classList.add('animateExpandFontSizeB'));
        
        await setTimeout(() => {
            dispatch(setExpanded(true));
            dispatch(setExpanding(false));
            document.querySelectorAll('.attendantList h1').forEach(e => e.classList.add('expanded'));
            document.querySelectorAll('.attendantList h1').forEach(e => e.classList.remove('animateExpandFontSizeB'));
            isExpandingRef.current = false;
        }, 1800);
    }, [dispatch]);

    const unExpandItems = () => {
        window.stopConfetti();
        dispatch(setExpanded(false));
        document.querySelectorAll('.attendantList h1').forEach(e => e.classList.remove('expanded'));
    };

    const resetSpinner = () => {
        const contD3 = d3.select(d3Container.current);
        
        // Primero removemos la transición y reseteamos la posición
        contD3.style('transition', 'none')
              .style('bottom', '0');
        
        // Limpiamos los elementos existentes
        document.querySelectorAll('.attendantList h1').forEach(e => e.remove());
        
        // Agregamos el nuevo texto
        contD3.selectAll('div')
            .data([awardIsEmpty ? 'Agotado' : '¡Comenzar!'])
            .enter()
            .append('h1')
            .text(a => a);
        
    }


    const spin = async () => {
        document.querySelectorAll('.attendantList h1').forEach(e => e.remove());
        const contD3 = d3.select(d3Container.current);
        let spinnerNames = [];
        // Calculamos REQUIRED_LENGTH basado en la duración
        // Base: 200 nombres para 10 segundos (10000ms)
        const REQUIRED_LENGTH = Math.max(50, Math.floor((spinDuration / 10000) * 20));
        let winner;
        
        // Filtramos los asistentes que ya han ganado
        let attendantsFiltered = attendants.filter(attendant => {
            if (!attendant.isAssisted) return false;
            const hasWon = lastWinners.some(winner => 
                winner.employeeId === attendant.employeeId
            );
            return !hasWon;
        });

        if (attendantsFiltered.length > 0) {
            spinnerNames.push('¡Comenzar!');
            
            const randomIndex = Math.floor(Math.random() * attendantsFiltered.length);
            winner = attendantsFiltered[randomIndex];
            
            // Para el efecto visual, usamos todos los asistentes (incluso los que ya ganaron)
            const allAttendantsForVisual = attendants.filter(a => a.isAssisted);
            
            // Llenamos secuencialmente hasta alcanzar REQUIRED_LENGTH
            let currentIndex = 0;
            while (spinnerNames.length < REQUIRED_LENGTH) {
                const attendant = allAttendantsForVisual[currentIndex];
                spinnerNames.push(`${attendant.firstName} ${attendant.lastName}`);
                
                // Incrementamos el índice y volvemos a 0 si llegamos al final
                currentIndex++;
                if (currentIndex >= allAttendantsForVisual.length) {
                    currentIndex = 0;
                }
            }

            // Aseguramos que el ganador sea el último
            spinnerNames[REQUIRED_LENGTH - 1] = `${winner.firstName} ${winner.lastName}`;

            console.log('ganador:', winner);
        } else {
            contD3.selectAll('div')
                .data(['No hay participantes disponibles para sortear.'])
                .enter()
                .append('h1')
                .text(a => a);
            
            return;
        }
        
        setTimeout(() => {
            if (!isCancelledRef.current) {
                let func = async () => {
                    window.startConfetti();
                    winnerSound.play().catch(e => console.log('Error playing sound:', e));
                    const winnerDto = {
                        ...winner,
                        awardId: currentAward.awardId,
                        eventId: currentEvent.eventId
                    };
                    if (connection) {
                        console.log('enviando ganador:', winnerDto);
                        await connection.send('NewPendingWinner', winnerDto);
                    }
                    setShowWinner(true);
                    document.getElementById('winnerInfo').classList.remove('d-none');
                    document.getElementById('winnerDepartmentValue').innerText = winner.department;
                    document.getElementById('winnerCodeValue').innerText = winner.employeeCode;
                };
                func();
            }
        }, spinDuration);

        contD3.selectAll('div')
            .data(spinnerNames)
            .enter()
            .append('h1')
            .attr('class', 'spin-item')
            .text(a => a);

        contD3.style('transition', `all 0s cubic-bezier(0, 0, 0.1, 1.0)`)
            .style('bottom', `-${(spinnerNames.length * 15) - 15}vh`);

        await expandWidgetA();

        // Añadir clase spinning al comenzar
        d3Container.current.classList.add('spinning');

        contD3.style('transition', `all ${spinDuration/1000}s cubic-bezier(0, 0, 0.1, 1.0)`)
            .style('bottom', `0vh`);

        // Remover clase spinning al terminar
        setTimeout(() => {
            d3Container.current.classList.remove('spinning');
            if (!isCancelledRef.current) {
                let func = async () => {
                    window.startConfetti();
                    winnerSound.play().catch(e => console.log('Error playing sound:', e));
                    const winnerDto = {
                        ...winner,
                        awardId: currentAward.awardId,
                        eventId: currentEvent.eventId
                    };
                    if (connection) {
                        console.log('enviando ganador:', winnerDto);
                        await connection.send('NewPendingWinner', winnerDto);
                    }
                    setShowWinner(true);
                    document.getElementById('winnerInfo').classList.remove('d-none');
                    document.getElementById('winnerDepartmentValue').innerText = winner.department;
                    document.getElementById('winnerCodeValue').innerText = winner.employeeCode;
                };
                func();
            } else {
                isCancelledRef.current = false;
                setIsCancelled(false);
            }
        }, spinDuration);
    };

    return (
        <div className={isExpanding ? 'mainWidgets mainWidgetA animateExpandWidgetA' : expanded ? 'mainWidgets mainWidgetA expanded' : 'mainWidgets mainWidgetA'}>
            {currentAward ? (
                <>
                    <div className='widgetHeader'>
                        <h2 className={isExpanding ? 'animateExpandFontSize' : expanded ? 'expanded' : ''}>
                            <span className='myBadget'>{currentAward.name}</span>
                            <span className='amount'>
                                {awardIsEmpty ? <span className='text-danger'></span> :<span>(<span className='d-none d-980-show me-2'>Quedan</span><span className=''>{currentAward.remainingQuantity}</span> de <span className=''>{currentAward.totalQuantity}</span>)</span>}
                            </span>
                        </h2>
                    </div>
                    <div className='widgetBody'>
                        {/* <SlotMachine 
                            textData={['Option #1', 'Option #2', 'Option #3', 'Option #4', 'Option #5', 'Option #6', 'Option #7', 'Option #8', 'Option #9', 'Option #10']}
                            random={true}
                        /> */}
                        <div className='d3Cont'>
                            <div className='attendantList' ref={d3Container} />
                        </div>
                        <div id='winnerInfo' className='d-none d-flex flex-column'>
                            <div className='winnerDepartment mb-2 d-flex align-items-center'>
                                <FontAwesomeIcon className='me-2' icon={faBuilding} />
                                <span className='me-2'>Departamento: </span>
                                <span className='myBadget ms-auto'><span id='winnerDepartmentValue'></span></span>
                            </div>
                            <div className='winnerCode d-flex align-items-center'>
                                <FontAwesomeIcon className='me-2' icon={faTicket} />
                                <span className='me-2'>Código: </span>
                                <span className='myBadget ms-auto'><span id='winnerCodeValue'></span></span>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="no-award-message">
                    <AlertMessage 
                        type="warning"
                        message="No hay premio seleccionado. Por favor, seleccione un premio para comenzar."
                        className="text-center"
                    />
                </div>
            )}
        </div>
    );
};

export default CurrentAward;